<template>
  <v-container id="user-table" fluid tag="section">
    <base-material-card
      icon="mdi-account-multiple"
      :title="$t('user.administrators')"
    >
      <users-form ref="editForm" :data="meta" @itemSaved="onSaved"></users-form>

      <delete-dialog
        ref="deleteConfirm"
        :url="apiQuery"
        :title="$t('user.delete')"
        @itemDeleted="onDeleted"
      >
        <template v-slot:default="item"
          >Are you sure you want to delete account of {{ item.name }}?</template
        >

        <template v-slot:error="error">
          <v-alert v-if="error.message" tile class="mb-0" type="error">{{
            error.message
          }}</v-alert>
        </template>

        <template v-slot:confirmed="item">
          <v-alert v-if="item.id" tile type="success" class="mb-0"
            >Account of {{ item.name }} deleted</v-alert
          >
        </template>
      </delete-dialog>

      <v-data-table
        :headers="headers"
        :items="items"
        :options.sync="options"
        :server-items-length="total"
        :loading="loading || !meta.roles"
        multi-sort
        :footer-props="{
          'items-per-page-options': [10, 20, 50],
        }"
      >
        <template v-slot:item.id="{ item }">
          <v-chip v-if="item.isNew" color="green">{{ item.id }}</v-chip>
          <span v-else>{{ item.id }}</span>
        </template>

        <template v-slot:top>
          <v-toolbar flat color="white">
            <v-spacer />
            <v-btn color="primary" dark class="mb-2" @click="onAdd">
              <v-icon left>mdi-account-plus</v-icon>
              {{ $t("user.add") }}
            </v-btn>
          </v-toolbar>
        </template>

        <template v-slot:item.actions="{ item }">
          <v-icon small class="mr-2" @click="onEdit(item)">mdi-pencil</v-icon>
          <v-icon small @click="onDelete(item)">mdi-delete</v-icon>
        </template>

        <template v-slot:item.roles="{ item }">
          <v-chip
            v-for="role in item.roles"
            :key="'role' + role"
            small
            :class="role === 1 ? 'red' : role === 2 ? 'orange' : ''"
            >{{ meta.roles ? meta.roles[role] : "" }}</v-chip
          >
        </template>
      </v-data-table>
    </base-material-card>
  </v-container>
</template>

<script>
import crudMixin from "mixins/crudTable";
import UsersForm from "./UsersForm";
import DeleteDialog from "@/components/crud/DeleteDialog";

export default {
  components: {
    UsersForm,
    DeleteDialog,
  },
  mixins: [crudMixin],
  data() {
    return {
      apiQuery: "/admins/",
      headers: [
        { text: "ID", align: "start", value: "id" },
        { text: this.$t("user.Name"), value: "name" },
        { text: this.$t("user.Email"), value: "email" },
        { text: this.$t("Roles"), value: "roles" },
        { text: this.$t("user.Actions"), value: "actions", sortable: false },
      ],
    };
  },
  created() {
    this.get(this.apiQuery + "create/").then((data) => {
      this.meta = data;
    });
  },
  methods: {
    onSaved(item, index) {
      if (index === -1) {
        this.total++;
        item.isNew = true;
        this.items.push(item);
        window.setTimeout(() => {
          this.$refs.editForm.clearForm();
        }, 1500);
      } else {
        Object.assign(this.items[index], item);
        window.setTimeout(() => {
          this.$refs.editForm.closeForm();
        }, 2500);
      }
    },
  },
};
</script>
