<template>
  <v-dialog
    v-model="showFormDialog"
    max-width="50vw"
    @click:outside="closeForm"
  >
    <v-card>
      <v-card-title>
        <span class="headline">{{ formTitle }}</span>
      </v-card-title>
      <v-form ref="form" v-model="valid" @submit.prevent="saveItem">
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="form.name"
                  prepend-icon="person"
                  :label="$t('user.Name')"
                  :rules="rules.name"
                ></v-text-field>

                <v-text-field
                  v-model="form.email"
                  :label="$t('user.Email')"
                  name="email"
                  prepend-icon="email"
                  type="text"
                  :disabled="sending || editedItem !== null"
                  :rules="rules.email"
                  @input="serverErrors = null"
                ></v-text-field>

                <v-text-field
                  v-model="form.password"
                  :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
                  :label="$t('user.Password')"
                  name="password"
                  prepend-icon="lock"
                  :type="showPass ? 'text' : 'password'"
                  counter
                  :rules="rules.password"
                  :disabled="sending"
                  @click:append="showPass = !showPass"
                  @input="serverErrors = null"
                />
                <v-alert
                  v-if="editedItem !== null"
                  text
                  dense
                  type="info"
                  class="mt-5"
                  >{{ $t("user.Leave password") }}</v-alert
                >
              </v-col>

              <v-col cols="12" md="6">
                <v-select
                  v-model="form.roles"
                  :items="forSelect(data.roles)"
                  item-text="text"
                  item-value="id"
                  chips
                  multiple
                  :label="$t('Roles')"
                >
                  <template v-slot:selection="{ item }">
                    <v-chip
                      :class="
                        item.id === 1 ? 'red' : item.id === 2 ? 'orange' : ''
                      "
                      >{{ item.text }}</v-chip
                    >
                  </template>
                </v-select>

                <v-select
                  v-model="form.permissions"
                  :items="forSelect(data.permissions)"
                  item-text="text"
                  item-value="id"
                  chips
                  multiple
                  :label="$t('roles and permissions.table.Direct permissions')"
                ></v-select>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="gray"
            :disabled="sending || savedItem != null"
            @click="closeForm"
            >{{ $t("button.Cancel") }}</v-btn
          >
          <v-btn
            type="submit"
            :disabled="sending || savedItem != null"
            color="primary"
            >{{ $t("button.Save") }}</v-btn
          >
        </v-card-actions>
      </v-form>
      <v-progress-linear
        v-if="sending"
        color="deep-purple accent-4"
        indeterminate
        rounded
        height="6"
      ></v-progress-linear>
      <v-alert v-if="errorMessage" tile class="mb-0" type="error">{{
        errorMessage
      }}</v-alert>
      <v-alert v-if="savedItem" tile type="success" class="mb-0"
        >{{ $t("user.The user") }} {{ savedItem.id }} {{ $t("user.was saved") }}
      </v-alert>
    </v-card>
  </v-dialog>
</template>

<script>
import crudMixin from "mixins/crudForm";
import validate from "@/plugins/validate";

export default {
  mixins: [crudMixin],
  props: {
    data: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      apiQuery: "/admins/",
      showPass: false,
      defaultItem: {
        name: "",
        email: "",
        password: "",
      },
      rules: {
        name: [(v) => !!v || "Required."],
        password: [
          (v) => (this.editedItem === null ? !!v || "Required." : true),
          (v) => (v ? v.length >= 8 || "Min 8 characters" : true),
          () =>
            this.serverErrors && this.serverErrors.password
              ? this.serverErrors.password[0]
              : true,
        ],
        email: [
          (v) => !!v || "Required.",
          (v) => {
            return validate.email.test(v) || "Invalid e-mail.";
          },
          () =>
            this.serverErrors && this.serverErrors.email
              ? this.serverErrors.email[0]
              : true,
        ],
      },
    };
  },

  computed: {
    formTitle() {
      return this.editedItem === null
        ? this.$t("user.add")
        : this.$t("Edit user");
    },
  },

  methods: {},
};
</script>
